import React from 'react';
import { Button } from 'bf-ui';

import styles from './errorBoundary.wrapper.scss';

class ErrorBoundaryWrapper extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    // console.log({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <div className="error_boundary_wrapper">
            <h1>Oops, there is an error!</h1>
            <a href="/">
              <Button variant="contained">Try again</Button>
            </a>
          </div>
          <style jsx>{styles}</style>
        </>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundaryWrapper;
